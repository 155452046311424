import React, { useEffect, useState } from 'react';
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import { useLojas } from '../../data/Lojas';
import { CenteredContainer, TableContainer } from './style';
import { Radio } from '@atlaskit/radio';
import { Loja } from '../../protocols/Loja';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { CenterTableContent } from '~/pages/Usuario/components/Table/styles';
import Checkbox from '@atlaskit/checkbox';

type TableProps = {
  form: UseFormReturn<FieldValues, any>;
};

export const Table: React.FC<TableProps> = ({ form }) => {
  const { lojas } = useLojas();

  const [listLojas, setListLojas] = useState<Loja[]>(lojas);

  const { setValue } = form;

  useEffect(() => {
    setListLojas(lojas);
    setValue('lojas', lojas);
  }, [lojas]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'flg_padrao',
      headerName: 'Padrão?',
      width: 85,
      sortable: false,
      align: 'center',
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <>
            <CenteredContainer>
              <Radio
                value={row.cod_loja}
                name="flg_padrao"
                isChecked={row.flg_padrao}
                onChange={handleChangeLojaPadrao}
              />
            </CenteredContainer>
          </>
        );
      },
    },
    {
      field: 'label',
      headerName: 'Loja',
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: 'flg_lib_financeiro',
      headerName: 'Lib. Financeiro?',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            <Checkbox
              isChecked={row.flg_lib_financeiro}
              isDisabled={false}
              onChange={() => handleLiberador(row)}
            />
          </CenterTableContent>
        );
      },
    },
    {
      field: 'flg_vendedor',
      headerName: 'Vendedor?',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: { row: any }) => {
        const { row } = params;
        return (
          <CenterTableContent>
            <Checkbox
              isChecked={row.flg_vendedor}
              isDisabled={false}
              onChange={() => handleVendedor(row)}
            />
          </CenterTableContent>
        );
      },
    },
  ];

  const handleLiberador = (row: any) => {
    const newLojas = listLojas.map((loja: Loja) => {
      loja.cod_loja === row.cod_loja &&
        (loja.flg_lib_financeiro = !row.flg_lib_financeiro);
      return loja;
    });
    setValue('lojas', newLojas);
    setListLojas(newLojas);
  };

  const handleVendedor = (row: any) => {
    const newLojas = lojas.map((loja: Loja) => {
      loja.cod_loja === row.cod_loja && (loja.flg_vendedor = !row.flg_vendedor);
      return loja;
    });
    setValue('lojas', newLojas);
    setListLojas(newLojas);
  };

  const handleChangeLojaPadrao = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = +event.target.value;

    changeDefault(value);
  };

  const changeDefault = (value: number) => {
    const newLojas = listLojas.map((loja) => {
      if (loja.cod_loja === value) {
        loja.flg_padrao = true;
        setValue('cod_loja', loja.cod_loja);
      } else {
        loja.flg_padrao = false;
      }
      return loja;
    });
    setListLojas(newLojas);
  };

  return (
    <TableContainer>
      <DataGrid
        rows={listLojas}
        columns={columns}
        pageSize={5}
        disableSelectionOnClick
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
        }}
      />
    </TableContainer>
  );
};
