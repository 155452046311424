import * as yup from 'yup';

export const schema = yup
  .object({
    loja: yup.number().required('Loja é obrigatória.'),
    cod_pessoa: yup
      .object()
      .shape({
        label: yup.string().required('Fornecedor é obrigatório.'),
        value: yup.number().required('Fornecedor é obrigatório.'),
      })
      .typeError('Fornecedor é obrigatório.'),
    cod_perfil: yup
      .object()
      .shape({
        label: yup.string().required('Perfil é obrigatório.'),
        value: yup.number().required('Perfil é obrigatório.'),
      })
      .typeError('Perfil é obrigatório.'),
    cod_especie: yup
      .object()
      .shape({
        label: yup.string().required('Espécie é obrigatória.'),
        value: yup.number().required('Espécie é obrigatória.'),
      })
      .typeError('Espécie é obrigatória.'),
    cod_serie: yup.mixed().when('cod_perfil', {
      is: (value: any) => {
        if (value !== null) {
          return value?.tipo_emissao === 0;
        }
        return false;
      },
      then: yup.mixed().required().nullable(false),
      otherwise: yup
        .object()
        .shape({
          label: yup.string().required(),
          value: yup.string().required(),
        })
        .required(),
    }),
    dta_emissao: yup
      .string()
      .required('Data de emissão é obrigatória.')
      .nullable()
      .test(
        'is-valid-date',
        'Data de emissão deve ser menor ou igual à data de saída',
        function (value) {
          const entrada = this.parent.dta_entrada;
          const today = new Date().toISOString().slice(0, 10); // Data atual no formato AAAA-MM-DD
          return (
            !value ||
            ((!entrada || new Date(value) <= new Date(entrada)) &&
              new Date(value) <= new Date(today))
          );
        },
      ),
    dta_entrada: yup
      .string()
      .required('Data de saída é obrigatória.')
      .nullable()
      .test(
        'is-future-or-today',
        'Data de saída deve ser maior ou igual à data atual',
        function (value) {
          const today = new Date().toISOString().slice(0, 10); // Data atual no formato AAAA-MM-DD
          return !value || new Date(value) >= new Date(today);
        },
      ),
  })
  .required();
