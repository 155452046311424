import React, { useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { InputPassword, InputText } from '~/components/NovosInputs';
import { TimezoneContainer } from '../Containers/TimezoneContainer';
import useAuth from '~/hooks/useAuth';
import { validaEmail } from '~/utils/functions';
import PasswordChecklist from 'react-password-checklist';
import { Tooltip } from '@material-ui/core';

type FormProps = {
  form: UseFormReturn<FieldValues, any>;
  updateForm: boolean;
  validPass: boolean;
  setValidPass: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Form: React.FC<FormProps> = ({
  form,
  updateForm,
  validPass,
  setValidPass,
}) => {
  const {
    control,
    formState,
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
  } = form;

  const { errors } = formState;
  const auth = useAuth();

  const [isFocused, setIsFocused] = useState(false);

  const senhaValue = watch('senha') ?? '';

  useEffect(() => {
    if (auth.user) {
      const { id, usuario, email, timezone, cod_pessoa } = auth.user;

      const userEmail = updateForm ? getValues('email') : email;

      if (!validaEmail(userEmail || '')) {
        setValue('email', getValues('email'));
        setError('email', { type: 'validate' }, { shouldFocus: true });
      } else {
        setValue('email', email);
        clearErrors('email');
      }

      setValue('des_usuario', usuario);
      setValue('cod_pessoa', cod_pessoa);
      setValue('timezone', { value: timezone, label: timezone });
      setValue('cod_usuario', id);
    }
  }, [auth.user, clearErrors, getValues, setError, setValue, updateForm]);

  useEffect(() => {
    if (!validPass && senhaValue.length > 0) {
      setError('senha', { type: 'required' });
    } else clearErrors('senha');
  }, [clearErrors, senhaValue.length, setError, validPass]);

  return (
    <>
      <Row>
        <Col sm={12} md={12} lg={12} xl={12}>
          <InputText
            label="Nome"
            placeholder="Digite o nome do usuário"
            maxLength={100}
            name="des_usuario"
            register={register}
            disabled={false}
            caseInput="upper"
            isError={!!errors.des_usuario}
            control={control}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={12} md={12} lg={6} xl={6}>
          <InputText
            label="E-mail"
            placeholder="Digite o e-mail"
            maxLength={100}
            name="email"
            register={register}
            caseInput="lower"
            disabled={false}
            isError={!!errors.email}
            control={control}
          />
        </Col>
        <Col sm={12} md={12} lg={3} xl={3}>
          <div tabIndex={-1}>
            <Tooltip
              open={isFocused}
              disableHoverListener
              onClose={() => setIsFocused(false)}
              title={
                <PasswordChecklist
                  rules={['minLength', 'specialChar', 'capital']}
                  minLength={6}
                  value={senhaValue}
                  messages={{
                    minLength: 'Deve ter pelo menos 6 caracteres.',
                    specialChar: 'Deve ter pelo menos 1 caractere especial.',
                    capital: 'Deve ter pelo menos 1 letra maiúscula.',
                  }}
                  iconSize={13}
                  style={{ fontSize: '0.9375rem' }}
                  onChange={(isValid) => setValidPass(isValid)}
                />
              }
            >
              <InputPassword
                placeholder="Digite a senha"
                label="Senha"
                maxLength={100}
                name="senha"
                register={register}
                disabled={false}
                isError={!!errors.senha}
                control={control}
                onFocus={() => setIsFocused(true)}
                onClick={() => {
                  setIsFocused(true);
                }}
                style={{
                  borderBottom:
                    !validPass && senhaValue?.length > 0
                      ? '2px solid #cf1919'
                      : '',
                }}
              />
            </Tooltip>
          </div>
        </Col>
        <Col sm={12} md={12} lg={3} xl={3}>
          <TimezoneContainer form={form} />
        </Col>
      </Row>
    </>
  );
};
